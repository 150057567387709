

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
//import BoolUpdateRow from "@/portals/shared/cell/bool/BoolUpdateRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";
import IntegerUpdateRow from "@/portals/shared/cell/integer/IntegerUpdateRow.js";

import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
//import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
//import Checklist from '@/domain/model/checklist/CheckList.js';

import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import VehicleTypeUpdateRow from "@/portals/shared/cell/vehicle/types/VehicleTypeUpdateRow.js";
import VehicleBrakeUpdateRow from "@/portals/shared/cell/vehicle/brakes/VehicleBrakeUpdateRow.js";
import VehicleUnitUpdateRow from "@/portals/shared/cell/vehicle/units/VehicleUnitUpdateRow.js";
import JurisdictionUpdateRow from "@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js";
import VehicleScheduleUpdateRow from "@/portals/shared/cell/vehicle/schedules/VehicleScheduleUpdateRow.js";

import PictureUpdateRow from "@/portals/shared/cell/picture/PictureUpdateRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
//import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow.js";

import SavingDisplayRow from "@/portals/shared/cell/saving/SavingDisplayRow.js";
import LoadingDisplayRow from "@/portals/shared/cell/loading/LoadingDisplayRow.js";
import CancellingDisplayRow from "@/portals/shared/cell/cancelling/CancellingDisplayRow.js";
import ChecklistUpdateRow from "@/portals/shared/cell/checklist/update/ChecklistUpdateRow.js";
import DefectTypeDisplayRow from "@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js";
import StateDisplayRow from "@/portals/shared/cell/state/StateDisplayRow.js";

import MC from "@/domain/session/MC.js";

import VehicleAddModel from "./VehicleAddModel.js";

import Vehicle from "@/domain/model/vehicle/Vehicle.js";

export default class VehicleAddUtils {

  static buildRowData(domain, panel, tableData) {
    var au = new VehicleAddUtils(domain, panel, tableData);
    au.start();
  }

  constructor(domain, panel, tableData) {
    this._panel = panel;
    this._tableData = tableData;
    this._domain = domain;
    this._logger = domain.logger();
    this.MC = new MC();
  }

  panel() {
    return this._panel;
  }

  vehicle() {
    var panel = this.panel();
    var model = panel.model();
    return model.vehicle();
  }

  logger() {
    return this._logger;
  }

  start() {
    var model = this.panel().model();
    var vehicle = this.vehicle();
    var summary = vehicle.summary();

    this.addRow(new SavingDisplayRow().withShow(model.isSaving()));
    this.addRow(new CancellingDisplayRow()
      .withShow(model.isCancelling())
      .withField(VehicleAddModel.Fields.CancelConfirm)
    );

    var images = vehicle.images().find();
    var image = images.first();

    if (model.isLoading()) {
      this.addRow(new LoadingDisplayRow().withMessage(this.MC.Vehicle.Add.Loading.value()));
    } else {
      var kids = [];

      if (this.vehicle().isNew()) {
        this.addRow(new TitleDisplayRow()
          .withAlign("centre")
          .withTitle(this.MC.Vehicle.Add.Title.value()));
      } else {
        if (vehicle.isTrailer()) {
          this.addRow(new TitleDisplayRow()
            .withAlign("centre")
            .withTitle(this.MC.Vehicle.Edit.TrailerTitle.value()));
        } else {
          this.addRow(new TitleDisplayRow()
            .withAlign("centre")
            .withTitle(this.MC.Vehicle.Edit.Title.value()));
        }
      }


      kids.push(
        new RowDisplayRow()
          .withBorder(false)
          .withChildren([

            new BoxDisplayRow()
              .withBorder(true)
              .withWidth(10)
              .withChildren([
                new TitleDisplayRow()
                  .withSubtitle(true)
                  .withTitle(this.MC.Vehicle.Add.RequiredDetails.value()),

                new StringDisplayRow()
                  .withValue("Note: * indicates that a value is required")
                  .withAlignLeft(),

                new RowDisplayRow()
                  .withChildren([
                    new StringUpdateRow()
                      .withName(this.MC.Vehicle.Add.Name.value())
                      .withField(Vehicle.FIELDS.NAME)
                      .withWidth(10)
                      .withDefaultValue(this.vehicle().name())
                      .withMandatoryValue(true),

                    new IntegerUpdateRow()
                      .withName(this.MC.Vehicle.Add.Year.value())
                      .withField(Vehicle.FIELDS.YEAR)
                      .withWidth(5)
                      .withDefaultValue(this.vehicle().year())
                      .withMandatoryValue(true),
                  ]),

                new RowDisplayRow()
                  .withChildren([
                    new VehicleTypeUpdateRow()
                      .withName(this.MC.Vehicle.Add.VehicleType.value())
                      .withField(Vehicle.FIELDS._CATEGORY)
                      .withDefaultValue(this.vehicle().category())
                      .withWidth(5)
                      .withMandatoryValue(true),

                    new VehicleBrakeUpdateRow()
                      .withName(this.MC.Vehicle.Add.BrakeType.value())
                      .withField(Vehicle.FIELDS.BRAKE_TYPE)
                      .withDefaultValue(this.vehicle().brakeType())
                      .withWidth(5)
                      .withMandatoryValue(true),

                    new VehicleUnitUpdateRow()
                      .withName(this.MC.Vehicle.Add.Units.value())
                      .withField(Vehicle.FIELDS.ODOMETER_TYPE)
                      .withWidth(5)
                      .withDefaultValue(this.vehicle().odometerType())
                      .withMandatoryValue(true),


                  ]),
                new RowDisplayRow()
                  .withBorder(false)
                  .withChildren([
                    new BoxDisplayRow()
                      .withWidth(4)
                      .withChildren([
                      ]),
                  ]),
                new RowDisplayRow()
                  .withChildren([
                    new JurisdictionUpdateRow()
                      .withName(this.MC.Vehicle.Add.Jurisdiction.value())
                      .withField(Vehicle.FIELDS.JURISDICTION)
                      .withWidth(5)
                      .withDefaultValue(this.vehicle().jurisdiction())
                      .withMandatoryValue(true),

                    new StringUpdateRow()
                      .withName(this.MC.Vehicle.Add.Plate.value())
                      .withField(Vehicle.FIELDS.PLATE)
                      .withWidth(5)
                      .withDefaultValue(this.vehicle().plate())
                      .withMandatoryValue(true),
                    new DateUpdateRow()
                      .withName(this.MC.Vehicle.Add.PlateExpiryDateLabel.value())
                      .withHint(this.MC.Vehicle.Add.PlateExpiryDateHint.value())
                      .withWidth(5)
                      .withDefaultValue(this.vehicle().plateExpiryDate())
                      .withField(Vehicle.FIELDS.PLATE_EXPIRY_DATE),
                  ]),
                new RowDisplayRow()
                  .withChildren([
                    new VehicleScheduleUpdateRow()
                      .withName(this.MC.Vehicle.Add.Schedule.value())
                      .withWidth(15)
                      .withField(Vehicle.FIELDS.SCHEDULE)
                      .withDefaultValue(this.vehicle().schedule())
                      .withMandatoryValue(true),
                  ]),
              ]),

            new BoxDisplayRow()
              .withBorder(false)
              .withWidth(5)
              .withChildren([
                new RowDisplayRow()
                  .withBorder(true)
                  .withChildren([
                    new TitleDisplayRow()
                      .withSubtitle(true)
                      .withWidth(15)
                      .withTitle(this.MC.Vehicle.Add.Picture.value()),
                    new PictureUpdateRow()
                      .withWidth(15)
                      .withDefaultValue(image)
                      .withField(Vehicle.FIELDS.IMAGE_DATA),
                  ]),
                new RowDisplayRow()
                  .withBorder(true)
                  .withChildren([
                    new TitleDisplayRow()
                      .withTitle("State and Status")
                      .withWidth(15)
                      .withSubtitle(true),
                    new DefectTypeDisplayRow()
                      .withValue(vehicle.defectType())
                      .withWidth(15)
                      .withLabel("Status"),
                    new StateDisplayRow()
                      .withValue(vehicle.state())
                      .withWidth(15)
                      .withLabel("State"),
                    //                                          new StringDisplayRow()
                    //                                            .withWidth(15)
                    //                                            .withDefaultValue("Mark as Out of Service - This means that nobody will be able to create an inspection"),
                  ])
              ]),
          ]));


      kids.push(new RowDisplayRow()
        .withBorder(false)
        .withChildren([
          new BoxDisplayRow()
            .withWidth(15)
            .withBorder(true)
            .withChildren([
              new TitleDisplayRow()
                .withSubtitle(true)
                .withTitle(this.MC.Vehicle.Add.NotesLabel.value()),
              new StringDisplayRow()
                .withAlignLeft()
                .withValue("Add any additional information about this vehicle. This will not be displayed to a driver on the mobile app."),
              new StringUpdateRow()
                .withName("Notes")
                .withDefaultValue(vehicle.notes())
                .withField(Vehicle.FIELDS.NOTES)
            ]),
        ])
      );


      var checklistRows = []
      if (this.allowsChecklists()) {
        checklistRows.push(
          new RowDisplayRow()
            .withBorder(true)
            .withChildren([
              new TitleDisplayRow()
                .withSubtitle(true)
                .withWidth(15)
                .withTitle(this.MC.Vehicle.Add.Checklist.value()),

              new StringDisplayRow()
                .withWidth(15)
                .withValue(this.MC.CheckList.DefinitionShort.value()),

              new ChecklistUpdateRow()
                .withName(this.MC.Vehicle.Add.Checklist.value())
                .withWidth(15)
                .withField(Vehicle.FIELDS.Checklists)
                .withDefaultValue(this.vehicle().checklists().find().first()),
            ])
        );
      }

      var startEndRow = new RowDisplayRow()
        .withBorder(true)
        .withChildren([
          new TitleDisplayRow()
            .withSubtitle(true)
            .withWidth(15)
            .withTitle(this.MC.Vehicle.Add.ServiceStartAndEndTitle.value()),
          new DateUpdateRow()
            .withName(this.MC.Vehicle.Add.StartDateLabel.value())
            .withHint(this.MC.Vehicle.Add.StartDateHint.value())
            .withDefaultValue(this.vehicle().startDate())
            .withWidth(15)
            .withField(Vehicle.FIELDS.START_DATE),
          new DateUpdateRow()
            .withName(this.MC.Vehicle.Add.EndDateLabel.value())
            .withHint(this.MC.Vehicle.Add.EndDateHint.value())
            .withDefaultValue(this.vehicle().endDate())
            .withWidth(15)
            .withField(Vehicle.FIELDS.END_DATE),
        ]);

      checklistRows.push(startEndRow);

      kids.push(new RowDisplayRow()
        .withBorder(false)
        .withChildren([
          new BoxDisplayRow()
            .withWidth(5)
            .withBorder(false)
            .withChildren(checklistRows),

          new BoxDisplayRow()
            .withWidth(5)
            .withBorder(false)
            .withChildren([
              new RowDisplayRow()
                .withBorder(true)
                .withChildren([
                  new TitleDisplayRow()
                    .withSubtitle(true)
                    .withWidth(15)
                    .withTitle(this.MC.Vehicle.Add.SafetyTitle.value()),
                  new DateUpdateRow()
                    .withName(this.MC.Vehicle.Add.Pmvi.value())
                    .withHint(this.MC.Vehicle.Add.PmviHint.value())
                    .withDefaultValue(this.vehicle().annualDate())
                    .withWidth(15)
                    .withField(Vehicle.FIELDS.ANNUAL_DATE),
                  new StringUpdateRow()
                    .withName(this.MC.Vehicle.Add.PmviId.value())
                    .withDefaultValue(this.vehicle().annualNumber())
                    .withHint(this.MC.Vehicle.Add.PmviIdHint.value())
                    .withWidth(15)
                    .withField(Vehicle.FIELDS.ANNUAL_NUMBER),
                ]),
              new RowDisplayRow()
                .withBorder(true)
                .withChildren([
                  new TitleDisplayRow()
                    .withSubtitle(true)
                    .withWidth(15)
                    .withTitle(this.MC.Vehicle.Add.VinWeightTitle.value()),

                  new StringUpdateRow()
                    .withName(this.MC.Vehicle.Add.Vin.value())
                    .withDefaultValue(this.vehicle().vin())
                    .withWidth(15)
                    .withField(Vehicle.FIELDS.VIN),

                  new IntegerUpdateRow()
                    .withName(this.MC.Vehicle.Add.Weight.value())
                    .withDefaultValue(this.vehicle().weight())
                    .withWidth(15)
                    .withField(Vehicle.FIELDS.WEIGHT),
                ]),
            ]),
          new BoxDisplayRow()
            .withWidth(5)
            .withBorder(true)
            .withChildren([
              new TitleDisplayRow()
                .withSubtitle(true)
                .withTitle(this.MC.Vehicle.Add.OdometerAndMoreTitle.value()),

              new StringUpdateRow()
                .withName(this.MC.Vehicle.Add.Odometer.value())
                .withWidth(3)
                .withDefaultValue(this.vehicle().odometer())
                .withField(Vehicle.FIELDS.ODOMETER),

              new StringUpdateRow()
                .withName(this.MC.Vehicle.Add.Summary.value())
                .withHint(this.MC.Vehicle.Add.SummaryHint.value())
                .withWidth(10)
                .withDefaultValue(summary)
                .withField(Vehicle.FIELDS.SUMMARY),

              new StringUpdateRow()
                .withName(this.MC.Vehicle.Add.Make.value())
                .withHint(this.MC.Vehicle.Add.MakeHint.value())
                .withDefaultValue(this.vehicle().make())
                .withField(Vehicle.FIELDS.MAKE),

              new StringUpdateRow()
                .withName(this.MC.Vehicle.Add.Model.value())
                .withHint(this.MC.Vehicle.Add.ModelHint.value())
                .withDefaultValue(this.vehicle().model())
                .withField(Vehicle.FIELDS.MODEL),

              new StringUpdateRow()
                .withName(this.MC.Vehicle.Add.Colour.value())
                .withDefaultValue(this.vehicle().colour())
                .withField(Vehicle.FIELDS.COLOUR),

            ]),
        ])
      );


      kids.push(new SpacerDisplayRow());

      kids.push(new RowDisplayRow()
        .withBorder(false)
        .withChildren([
          new SpacerDisplayRow()
            .withWidth(2),

          new ButtonDisplayRow()
            .withWidth(3)
            .withName(this.MC.CheckList.CancelButton.value())
            .withField(VehicleAddModel.Fields.CancelButton),

          new SpacerDisplayRow()
            .withWidth(1),

          this.createSaveAnotherRow(),

          new SpacerDisplayRow()
            .withWidth(1),

          new ButtonDisplayRow()
            .withBackgroundPrimaryAction()
            .withName(this.MC.CheckList.SaveButton.value())
            .withField(VehicleAddModel.Fields.SaveButton)
            .withWidth(3),

          new SpacerDisplayRow()
            .withWidth(2),

        ]));
      this.addRow(new BoxDisplayRow()
        .withBorder(true)
        .withChildren(kids));
    }

  }

  createSaveAnotherRow() {
    if (this.vehicle().isNew()) {
      return new ButtonDisplayRow()
        .withBackgroundPrimaryAction()
        .withName(this.MC.Vehicle.Add.SaveAnotherButton.value())
        .withField(VehicleAddModel.Fields.SaveAnotherButton)
        .withWidth(3);
    }
    return new SpacerDisplayRow().withWidth(3);
  }

  allowsChecklists() {
    if (this._domain) {
      return this._domain.session().company().find().features().find().allowsCheckLists();
    }
    return false;
  }

  addRow(row) {
    this._tableData.push(row);
  }
}